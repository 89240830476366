import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'customer', loadChildren: './customer/customer.module#CustomerPageModule' },
  { path: 'addcustomer', loadChildren: './addcustomer/addcustomer.module#AddcustomerPageModule' },
  { path: 'addcustomer/:id/:name/:desc', loadChildren: './addcustomer/addcustomer.module#AddcustomerPageModule' },
  { path: 'showcustomer/:id/:name/:desc', loadChildren: './showcustomer/showcustomer.module#ShowcustomerPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'training', loadChildren: './training/training.module#TrainingPageModule' },
  { path: 'trainer-rennen', loadChildren: './trainer-rennen/trainer-rennen.module#TrainerRennenPageModule' },
  { path: 'about', loadChildren: './about/about.module#AboutPageModule' },
  { path: 'trainer-training', loadChildren: './trainer-training/trainer-training.module#TrainerTrainingPageModule' },
  { path: 'trainer-pferde', loadChildren: './trainer-pferde/trainer-pferde.module#TrainerPferdePageModule' },
  { path: 'showhorses/:id/:name/:geb/:box/:papers/:owner/:stall', loadChildren: './showhorses/showhorses.module#ShowhorsesPageModule' },
  { path: 'horsesnew', loadChildren: './horsesnew/horsesnew.module#HorsesnewPageModule' },
  { path: 'horsesnew/:id/:name/:geb/:box/:papers/:ownerid/:owner/:stallid/:stall', loadChildren: './horsesnew/horsesnew.module#HorsesnewPageModule' },
  { path: 'trainer-training-add', loadChildren: './trainer-training-add/trainer-training-add.module#TrainerTrainingAddPageModule' },
  { path: 'details-lot-list/:id/:date/:name', loadChildren: './details-lot-list/details-lot-list.module#DetailsLotListPageModule' },
  { path: 'trainer-rennen-add', loadChildren: './trainer-rennen-add/trainer-rennen-add.module#TrainerRennenAddPageModule' },
  { path: 'training-lot', loadChildren: './training-lot/training-lot.module#TrainingLotPageModule' },
  { path: 'zusatz/:id/:name', loadChildren: './zusatz/zusatz.module#ZusatzPageModule' },
  { path: 'vetview', loadChildren: './vetview/vetview.module#VetviewPageModule' },
  { path: 'vetviewupdate/:id/:name/:vetid', loadChildren: './vetviewupdate/vetviewupdate.module#VetviewupdatePageModule' },
  { path: 'feedview', loadChildren: './feedview/feedview.module#FeedviewPageModule' },
  { path: 'feedviewupdate/:id/:name/:feedid', loadChildren: './feedviewupdate/feedviewupdate.module#FeedviewupdatePageModule' },
  { path: 'farrierview', loadChildren: './farrierview/farrierview.module#FarrierviewPageModule' },
  { path: 'farrierviewupdate/:id/:name/:farrierid', loadChildren: './farrierviewupdate/farrierviewupdate.module#FarrierviewupdatePageModule' },
  { path: 'vet-archiv', loadChildren: './vet-archiv/vet-archiv.module#VetArchivPageModule' },
  { path: 'vet-archiv-detail/:id/:name', loadChildren: './vet-archiv-detail/vet-archiv-detail.module#VetArchivDetailPageModule' },
  { path: 'feed-archiv', loadChildren: './feed-archiv/feed-archiv.module#FeedArchivPageModule' },
  { path: 'feed-archiv-detail/:id/:name', loadChildren: './feed-archiv-detail/feed-archiv-detail.module#FeedArchivDetailPageModule' },
  { path: 'farrier-archiv', loadChildren: './farrier-archiv/farrier-archiv.module#FarrierArchivPageModule' },
  { path: 'farrier-archiv-detail/:id/:name', loadChildren: './farrier-archiv-detail/farrier-archiv-detail.module#FarrierArchivDetailPageModule' },
  { path: 'work-archiv-detail/:id/:name', loadChildren: './work-archiv-detail/work-archiv-detail.module#WorkArchivDetailPageModule' },
  { path: 'work-archiv', loadChildren: './work-archiv/work-archiv.module#WorkArchivPageModule' },
  { path: 'work-add', loadChildren: './work-add/work-add.module#WorkAddPageModule' },
  { path: 'farriertraining', loadChildren: './farriertraining/farriertraining.module#FarriertrainingPageModule' },
  { path: 'chirotraining', loadChildren: './chirotraining/chirotraining.module#ChirotrainingPageModule' },
  { path: 'vettraining', loadChildren: './vettraining/vettraining.module#VettrainingPageModule' },
  { path: 'med-archiv', loadChildren: './med-archiv/med-archiv.module#MedArchivPageModule' },
  { path: 'med-archiv-detail/:id/:name', loadChildren: './med-archiv-detail/med-archiv-detail.module#MedArchivDetailPageModule' },
  { path: 'medview', loadChildren: './medview/medview.module#MedviewPageModule' },
  { path: 'medviewupdate/:id/:name/:medid', loadChildren: './medviewupdate/medviewupdate.module#MedviewupdatePageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

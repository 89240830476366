import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/Storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {



  public appPages = [
    {
      title: 'Rennen',
      url: '/trainer-rennen',
      icon: 'star'
    },
    {
      title: 'Training',
      url: '/trainer-training',
      icon: 'bicycle'
    },
    {
      title: 'Pferde',
      url: '/trainer-pferde',
      icon: 'book'
    },
    {
      title: 'Arbeit Archiv',
      url: '/work-archiv',
      icon: 'book'
    },
    {
      title: 'Tierarzt Archiv',
      url: '/vet-archiv',
      icon: 'book'
    },
    {
      title: 'Chiropraktiker Archiv',
      url: '/feed-archiv',
      icon: 'book'
    },
    {
      title: 'Schmied Archiv',
      url: '/farrier-archiv',
      icon: 'book'
    },
    {
      title: 'Medikamente Archiv',
      url: '/med-archiv',
      icon: 'book'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private storage: Storage
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.storage.get('session_storage').then((res)=>{
      if(res.customer_group == 1){
        this.router.navigate(['/trainer-rennen']);
      }else if(res.customer_group == 2){
      	this.router.navigate(['/training']);
      }else if(res.customer_group == 3){
      	this.router.navigate(['/vetview']);
      }else if(res.customer_group == 5){
      	this.router.navigate(['/feedview']);
      }else if(res.customer_group == 4){
        this.router.navigate(['/farrierview']);
      }else if(res.customer_group == 6){
      	this.router.navigate(['/medview']);
      }else{
        this.router.navigate(['/login']);
      }
    });
  }


}
